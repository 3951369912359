import React, { useEffect } from 'react';
import { OmdsCell, OmdsGrid, OmdsIcon } from '@mom-omds/react-design-system-components/dist/generated/components';
import './LandingPage.scss';
import LandingCard from './LandingCard';
import { setApplicationUtm } from '@om/redux/actions/digitalOnboarding/application';
import { useDispatch, useSelector } from 'react-redux';

const LandingPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const url = new URL(window.location.href);
        const utmSource = url.searchParams.get("utm_source");
        const utmId = url.searchParams.get("utm_id");

        if (utmSource) {
                dispatch(setApplicationUtm({"utmSource": utmSource, "utmUniqueKey": utmId}))
        } else {
                dispatch(setApplicationUtm({"utmSource": "", "utmUniqueKey": ""}))
        }

        dataLayer.push({
            "app": "omflending",
            "applicationNumber": "",
            "applicationOutcome": "",
            "environment": process.env.GATSBY_ENVIRONMENT_NAME,
            "event": window.location.pathname.split('/')[3],
            "eventOutcome": "",
            "page": window.location.pathname.split('/')[3]
        });
        
    }, []);

    return (
        <OmdsGrid>
            <OmdsCell className="omds-mt-m-7 omds-mt-12 omds-text-center mobile-grid inline-header" span="2-3" spanXs="row" spanMd="row">
                <span className="h4-light">
                    Before we {' '}
                </span>
                <span className="h4-medium">
                    get started
                </span>
            </OmdsCell>
            <OmdsCell className="omds-text-center omds-mt-3" span="row" spanMd="row">
                <p className="h6-light">Ensure you meet the below requirements:</p>
            </OmdsCell>
            <OmdsCell span="row" spanMd="3-10">
                <div className="landing-card-container landing-card-combined-container omds-mt-5">
                    <LandingCard imageUrl={'https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/bltf734b2ab2e7923da/62a9d6bdcf9b5d362d269630/South_African_Citizen.svg'} text={'South African citizen between the ages of 18 and 60 years old.'}/>
                    <LandingCard imageUrl={'https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/bltc0b88348f1f12339/62a9d6bd7f185768b2d06921/Monthly_Income.svg'} text={'Earn more than R2 500 per month before deductions.'}/>
                    <LandingCard imageUrl={'https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt147e89f5150f6f8b/62a9d6bd141eee29aa10be1f/Employed_or_on_Contract.svg'} text={'Permanently employed or on contract for more than 3 months.'}/>
                </div>
            </OmdsCell>

            {/* <OmdsCell className="omds-jc-center omds-flex" span="row" spanMd="4-9">
                <div>
                    <div className="omds-mt-m-7 omds-mt-11 omds-text-center mobile-grid">
                        <span className="h4-light">
                            The documents {' '}
                        </span>
                        <span className="h4-medium">
                            you will need
                        </span>
                    </div>
                    <div className="omds-mt-7 icon-field omds-c-gap-5">
                        <OmdsIcon
                            className="check-icon"
                            name="check_circle"
                            size={24} />
                        Identity document
                    </div>
                    <div className="icon-field omds-c-gap-5">
                        <OmdsIcon
                            className="check-icon"
                            name="check_circle"
                            size={24} />
                        3 months Bank Statements
                    </div>
                    <div className="icon-field omds-c-gap-5">
                        <OmdsIcon
                            className="check-icon"
                            name="check_circle"
                            size={24} />
                        Latest payslip
                    </div>
                </div>
            </OmdsCell> */}
        </OmdsGrid>


    );
}
export default LandingPage