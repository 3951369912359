export function formatToDecimalPlaces(val, fraction = 2) {
    const decimalIndicator = '.';
    return new Intl.NumberFormat('en-ZA', { minimumFractionDigits: 0, maximumFractionDigits: fraction })
        .formatToParts(val)
        .map(x => {
            if (x.type === 'decimal') return decimalIndicator;
            if (x.type === 'fraction' && x.value.length < 2) return x.value + '0';
            return x.value;
        })
        .join('');
}
