import * as ActionTypes from './types';

export function storeApplicantLoanAmount(amount) {
    return {
        type: ActionTypes.STORE_APPLICANT_SELECTED_QUOTE_LOAN_AMOUNT,
        value: amount
    }
}

export function storeApplicantLoanTerm(term) {
    return {
        type: ActionTypes.STORE_APPLICANT_SELECTED_QUOTE_LOAN_TERM,
        value: term
    }
}
