import React from 'react';
import { useLocation } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { OmApplicationWizardHeader } from '@om/component-library-react';
import { OmdsIcon } from '@mom-omds/react-design-system-components/dist/generated/components';
import { PAGE_INDEXES } from '@om/redux/actions/digitalOnboarding/types';
import { getPageIndex } from '../functions/Navigation';
import { OmdsStepper, OmdsStepperStep, OmdsGrid, OmdsCell, OmdsButton } from '@mom-omds/react-design-system-components/dist/generated/components';
import './ApplicationHeader.scss';
import { setReturnToProduct } from '@om/redux/actions/digitalOnboarding/routing';
import { toggleQualifyingSideDrawerFromLendingJourney } from '@om/redux/actions/publicWeb/qualifyingCheckCallMeBack';

const ApplicationHeader = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const pageIndexFromURL = getPageIndex(location.pathname);
    const showStepper = useSelector(state => state.digitalOnboarding.routing.isStepperVisible);
    const steps = useSelector(state => state.digitalOnboarding.routing.steps);
    const activeStep = useSelector(state => state.digitalOnboarding.routing.activeStep);

    const getHeaderTitle = () => {
        return PAGE_INDEXES[pageIndexFromURL]?.headerTitle;
    };

    const handleCallMeBack = () =>{
        dispatch(toggleQualifyingSideDrawerFromLendingJourney(true));
    };

    return (
        <>
            <OmApplicationWizardHeader helpNumber="0860 000 886" headerTexture="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt8ae852fd862c7faa/5ed0b6c0ccde496d39bc4e7c/Mobile_Header_Texture_-_RA.jpg">
                <div slot="wizard-title">
                    <h5><strong>{getHeaderTitle()}</strong></h5>
                </div>
                <OmdsButton
                    slot="contact-button"
                    outline
                    regular
                    onClick={ () => handleCallMeBack() }
                >CALL ME BACK</OmdsButton>
                <a slot="close-button">
                    <OmdsIcon name="clear" size={24} onClick={() => dispatch(setReturnToProduct())} />
                </a>
                <OmdsStepper slot="progress-bar" className={`${showStepper ? '' : 'omds-hidden'} stepper`}>
                    {steps.map(s => <OmdsStepperStep key={s.stepTitle} is-active={s.isActive} is-complete={s.isComplete}>{s.stepTitle}</OmdsStepperStep>)}
                </OmdsStepper>
            </OmApplicationWizardHeader>

            <OmdsGrid>
                <OmdsCell span="row">
                    <span className={`${showStepper ? 'label-medium stepper-text omds-mt-7' : 'omds-hidden'}`}>Step {activeStep} of {steps.length}</span>
                </OmdsCell>
            </OmdsGrid>
        </>
    );
};

export default ApplicationHeader;
