import React, {useEffect} from 'react';
import { createStructuredSelector } from 'reselect';
import { PAGE_INDEXES, PARENT_URL } from '@om/redux/actions/digitalOnboarding/types';
import { useLocation } from '@reach/router';
import { OmApplicationWizardFooter, OmButton, OmModal, OmIcon } from '@om/component-library-react';
import { OmdsButton } from '@mom-omds/react-design-system-components/dist/generated/components';
import { navigate } from 'gatsby';
import {useDispatch, useSelector} from 'react-redux';
import { navigateNext, navigateBack, resetApplicationFlow } from '@om/redux/actions/digitalOnboarding/routing';
import { getPageIndex } from '../functions/Navigation';
import { getActivePageIndex } from '@om/redux/selectors/digitalOnboarding/routing';
import { setDocumentModal } from '@om/redux/actions/digitalOnboarding/documentStore';
import './ApplicationFooter.scss';

const ApplicationFooter = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const routing = useSelector((state) => state.digitalOnboarding.routing);
    const pageIndexFromURL = getPageIndex(location.pathname);
    const canShowFooter = useSelector(state => state.digitalOnboarding.routing.isFooterVisible);
    const { activePageIndex } = useSelector(createStructuredSelector({ activePageIndex: getActivePageIndex }));

    const routingStore = useSelector(state => state.digitalOnboarding.routing);

    useEffect(() => {
        if(pageIndexFromURL !== activePageIndex) {
            navigate(PAGE_INDEXES[activePageIndex].url);
        }
    }, [activePageIndex, pageIndexFromURL]);

    useEffect(() => {
        if (routing.allowNavigation) {
            navigate(PAGE_INDEXES[activePageIndex].url);
        }
    }, [activePageIndex, routing.allowNavigation]);

    useEffect(() => {
        if(routing.returnToProductPage) {
            dispatch(resetApplicationFlow());
            navigate(PARENT_URL);
        }
    }, [routing.returnToProductPage, dispatch]);

    const navigateNextRouting = () => {

        if (PAGE_INDEXES[pageIndexFromURL].nextPageId === 7) {
            dispatch(setDocumentModal(true));
        } else {
            dispatch(navigateNext());
        }
    }

    return (
        <OmApplicationWizardFooter
            index={pageIndexFromURL}
            totalCount={PAGE_INDEXES.length}
            className={`${canShowFooter ? '' : 'omds-hidden'}`}
        >
            <OmButton
                slot="left"
                type="text"
                size="small"
                data-qa="LeftButton"
                text={PAGE_INDEXES[pageIndexFromURL]?.footerLeft ?? 'PREVIOUS'}
                disabled={false}
                onClick={() => dispatch(navigateBack())}
            />
            <OmdsButton
                className="desktop-button"
                slot="right"
                iconName="arrow_forward"
                data-qa="RightButton"
                primary
                regular
                isDisabled={!routing.canProceed}
                onClick={() => navigateNextRouting()}
            >
                {PAGE_INDEXES[pageIndexFromURL]?.footerRight ?? 'CONTINUE'}
            </OmdsButton>
            <OmdsButton
                className="mobile-button"
                slot="right"
                iconName="arrow_forward"
                data-qa="RightButton"
                primary
                small
                isDisabled={!routing.canProceed}
                onClick={() => navigateNextRouting()}
            >
                {PAGE_INDEXES[pageIndexFromURL]?.footerRight ?? 'CONTINUE'}
            </OmdsButton>

        </OmApplicationWizardFooter>
    );

}

export default ApplicationFooter;