import { useEffect, useRef } from 'react'


export const useScrollRef = (value, comparer = null) => {
    const componentRef = useRef();

    useEffect(() => {
        value && componentRef.current.scrollIntoView({behavior: 'smooth'});
    }, [value, comparer]);

    return componentRef;
}
