import React, { useEffect,  } from 'react';
import ApplicationLayout from '../layouts/ApplicationLayout';
import LandingPage from '../applications/digitalOnboarding/landingPage/LandingPage';
import { Router } from '@reach/router';
import PersonalDetailsPage from '../applications/digitalOnboarding/personalDetailsPage/PersonalDetailsPage';
import EmploymentDetails from '../applications/digitalOnboarding/employmentDetailsPage/EmploymentDetails';
import AffordabilityPage from '../applications/digitalOnboarding/affordabilityPage/AffordabilityPage';
import QuotePage from '../applications/digitalOnboarding/quotePage/QuotePage';
import OTPPage from '../applications/digitalOnboarding/otpPage/OTPPage';
import AddressPage from '../applications/digitalOnboarding/addressPage/AddressPage';
import DocumentsPage from '../applications/digitalOnboarding/documentsPage/DocumentsPage';
import SuccessPage from '../applications/digitalOnboarding/successPage/SuccessPage';
import ApplicationHeader from '../applications/digitalOnboarding/applicationHeader/ApplicationHeader';
import ApplicationFooter from '../applications/digitalOnboarding/applicationFooter/ApplicationFooter';
import { PAGES } from '@om/redux/actions/digitalOnboarding/types';
import { setReturnToProduct, schedulePageRequests } from '@om/redux/actions/digitalOnboarding/routing';
import { useDispatch, useSelector } from 'react-redux';
import { getLookupData } from '@om/redux/actions/digitalOnboarding/lookupData';
import { OmModalLoader, OmModal, OmButton } from '@om/component-library-react';
import { graphql } from 'gatsby';
import { navigateNext, setDocumentUploadSkip } from '@om/redux/actions/digitalOnboarding/routing';
import { setDocumentModal } from '@om/redux/actions/digitalOnboarding/documentStore';
import { ADD_ADDRESS_REQUEST_SUCCESS, FILE_UPLOAD_REQUEST, MAKE_APPLICATION_REQUEST } from '@om/redux/actions/digitalOnboarding/types'

const DigitalOnboardingPage = ({data}) => {
    const dispatch = useDispatch();

    const {
        faqcards
    } = data.contentstackDigitalOnboardingApplication;

    const apiError = useSelector(state => state.digitalOnboarding.routing.apiError);
    const isFetching = useSelector(state => state.digitalOnboarding.routing.isFetching);
    const lookupData = useSelector((state) => state.digitalOnboarding.lookupData);
    const documentsModal = useSelector((state) => state.digitalOnboarding.documentStore.documentsModal);

    useEffect(() => {
        if(lookupData.data.length === 0){
            dispatch(getLookupData());
        }
    }, [dispatch, lookupData.data.length]);

    const backToLoans = () => dispatch(setReturnToProduct());
    const closeDocumentsModal = () => dispatch(setDocumentModal(false));
    const submitApplication = () => {
        dispatch(setDocumentUploadSkip(true));
        dispatch(schedulePageRequests([ADD_ADDRESS_REQUEST_SUCCESS, FILE_UPLOAD_REQUEST, MAKE_APPLICATION_REQUEST]));
    }

    return (
        <ApplicationLayout>
            <ApplicationHeader />
            <OmModal
                open={apiError}
                isCentered="true"
                overlayName="oops-error"
                dismissible
                className="oops-error"
            >
                <div slot="modal-content" className="cancel-modal">
                    <om-icon image-url="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt7379af7d0a25dd73/5ee9f203557e3a516a2d9a29/desktop_monitor_smiley.svg" />
                    <h4>Oops! Something went wrong.</h4>
                    <h6>Brace yourself till we get the error fixed. You may also refresh the page or try again later.</h6>
                    <div className="oops-error-modal-buttons">
                        <OmButton
                            type="primary"
                            text="Back To Loans"
                            size="large"
                            onClick={backToLoans}
                        />
                    </div>
                </div>
            </OmModal>

            <OmModal
                open={documentsModal}
                isCentered="true"
                overlayName="choose-documents"
                dismissible
                onOnClosed={() => dispatch(setDocumentModal(false))}
                className="document-upload-modal"
            >
                <div slot="modal-content" className="cancel-modal">
                    <h4>Upload Supporting Documents</h4>
                    <h6>Uploading of supporting documents is not compulsory to submit the application but essential to fast track your application.</h6>
                    <div className="choose-documents-modal-buttons">
                        <OmButton
                            type="secondary"
                            text="Submit without documents"
                            size="small"
                            onClick={() => {
                                submitApplication();
                                closeDocumentsModal();
                            }}
                        />
                        <OmButton
                            type="primary"
                            text="Upload Documents"
                            size="small"
                            onClick={() => {
                                dispatch(setDocumentUploadSkip(false));
                                dispatch(navigateNext());
                                closeDocumentsModal();
                            }}
                        />

                    </div>
                </div>
            </OmModal>
            <Router className="footer-padding">
                <LandingPage path={PAGES.LANDING} />
                <PersonalDetailsPage path={PAGES.PERSONAL_DETAILS} />
                <EmploymentDetails path={PAGES.EMPLOYMENT_DETAILS} />
                <AffordabilityPage path={PAGES.AFFORDABILITY} />
                <QuotePage accordianDetails={faqcards} path={PAGES.QUOTE} />
                <OTPPage path={PAGES.OTP} />
                <AddressPage path={PAGES.ADDRESS} />
                <DocumentsPage path={PAGES.DOCUMENTS} />
                <SuccessPage path={PAGES.SUCCESS} />
            </Router>
            <ApplicationFooter />
            <OmModalLoader
                verticalAlignMiddle
                loaderText="Loading..."
                open={isFetching}
            />
        </ApplicationLayout>
    );
};

export const pageQuery = graphql`
query DigitalOnboardingApplicationPageQuery($id: String!) {
    contentstackDigitalOnboardingApplication(id: { eq: $id }) {
        uid
        title
        faqcards {
            ...FaqCards
          }
    }
}
`;

export default DigitalOnboardingPage;
