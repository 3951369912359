import React from 'react';
import { graphql } from 'gatsby';
import { OmFaqCard } from '@om/component-library-react';

const FaqCard = ({
    faqCardContent,
    slot
}) => {
    if(!faqCardContent) {
        return '';
    }

    const {
        expanded,
        expanded_text,
        closed_text
    } = faqCardContent;

    return (
        <OmFaqCard
            slot={slot}
            closedText={closed_text}
            expanded={expanded}
            expandedText={expanded_text}
        >
            {
                expanded_text ? <div slot="expanded-text" dangerouslySetInnerHTML={{__html: expanded_text}} /> : ''
            }
        </OmFaqCard>
    );
};

export const pageQuery = graphql`

fragment FaqCard on Contentstack_faq_card {
  	uid
  	expanded
  	expanded_text
  	closed_text
}
`;

export default FaqCard