import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OmdsCell, OmdsGrid, OmdsSlider } from '@mom-omds/react-design-system-components/dist/generated/components';
import { ADMIN_FEE, CREDIT_LIFE, INITIATION_FEE, INTEREST_RATE, LOAN_AMOUNT, LOAN_AMOUNT_STEP_SIZE, LOAN_INSTALMENT, LOAN_TERM, MAX_AMOUNT, ABS_MAX_AMOUNT, MAX_TERM, MIN_AMOUNT, MIN_TERM, MONTH_STEP_SIZE } from '@om/redux/actions/digitalOnboarding/types';
import { storeApplicantLoanAmount, storeApplicantLoanTerm } from '@om/redux/actions/digitalOnboarding/quoteDetails';
import './QuotePage.scss';
import DecimalNumberLabel from '../DecimalNumberLabel';
import FaqCards from '../../../components/FaqCards';

const QuoteValue = ({ heading, value }) => (
    <div key={heading} className="monitory-value">
        <span className="quote-fees h4-regular">{value}</span>
        <span className="text-link-small">{heading}</span>
    </div>
);

const QuoteFee = ({ heading, value }) => (
    <div key={heading} className="monitory-value">
        <span className="quote-fees h5-medium">{value}</span>
        <span className="text-link-small">{heading}</span>
    </div>
);

const QuotePage = ({ accordianDetails }) => {
    const dispatch = useDispatch();
    const quoteDetails = useSelector(state => state.digitalOnboarding.quoteDetails.storeValues);

    const stepAmount = quoteDetails[LOAN_AMOUNT_STEP_SIZE];
    const stepMonth = quoteDetails[MONTH_STEP_SIZE];
    const loanAmount = quoteDetails[LOAN_AMOUNT];
    const loanTerm = quoteDetails[LOAN_TERM];
    const minAmount = quoteDetails[MIN_AMOUNT];
    const maxAmount = quoteDetails[MAX_AMOUNT];
    const absMaxAmount = quoteDetails[ABS_MAX_AMOUNT];
    const minTerm = quoteDetails[MIN_TERM];
    const maxTerm = quoteDetails[MAX_TERM];
    const monthlyInstalment = quoteDetails[LOAN_INSTALMENT];
    const initiationFee = quoteDetails[INITIATION_FEE];
    const creditLife = quoteDetails[CREDIT_LIFE];
    const adminFee = quoteDetails[ADMIN_FEE];
    const interestRate = quoteDetails[INTEREST_RATE];

    const quoteInstalments = [{
        heading: 'Loan amount',
        value: <DecimalNumberLabel value={loanAmount} prefix='R' />
    },{
        heading: 'Loan term',
        value: <DecimalNumberLabel value={loanTerm} suffix=" months" />
    },{
        heading: 'Total montly premium',
        value: <DecimalNumberLabel value={monthlyInstalment} prefix='R' />
    }];

    const quoteFees = [{
        heading: 'Once off initiation fee',
        value: <DecimalNumberLabel value={initiationFee} prefix='R' />
    },{
        heading: 'Monthly admin fee',
        value: <DecimalNumberLabel value={adminFee} prefix='R' />
    }, {
        heading: 'Monthly credit Life',
        value: <DecimalNumberLabel value={creditLife} prefix='R' />
    }, {
        heading: 'Interest rate',
        value: <DecimalNumberLabel value={interestRate} suffix=" %" />
    },{
        heading: 'Total amount paid back',
        value: <DecimalNumberLabel value={monthlyInstalment * loanTerm} prefix='R'/>
    } ];

    return (
        <OmdsGrid>
            <OmdsCell className="omds-mt-12 heading-text" span="row" spanMd="2..">
                <span className="heading-font h4-light">Your Quote</span>
            </OmdsCell>
            <OmdsCell className="omds-mt-1" span="row" spanMd="2..">
                <p className="subtitle-text">Calculate how much you qualify for</p>
            </OmdsCell>
            <OmdsCell className="omds-mt-7" span="row" spanMd="2..">
                <p className="h6-bold">You qualify for a maximum loan of <DecimalNumberLabel value={absMaxAmount} prefix='R' /></p>
            </OmdsCell>
            <OmdsCell span="row" span-md="3-10" className="omds-mt-7">
                <OmdsSlider min={minAmount} max={maxAmount} step={stepAmount} value={loanAmount} onPersist={e => dispatch(storeApplicantLoanAmount(e.detail))}>
                    Select loan amount
                </OmdsSlider>
            </OmdsCell>
            <OmdsCell span="row" span-md="3-10" className="omds-mt-7">
                <OmdsSlider min={minTerm} max={maxTerm} step={stepMonth} value={loanTerm} currency-symbol=" " onPersist={e => dispatch(storeApplicantLoanTerm(e.detail))}>
                    Select loan term
                    <span slot="min-label">{minTerm} months</span>
                    <span slot="max-label">{maxTerm} months</span>
                    <span slot="label">{loanTerm} months</span>
                </OmdsSlider>
            </OmdsCell>
            <OmdsCell className="omds-mt-7 omds-gap-m-6 omds-gap-7 monitory-values-container" span="row" span-md="3-10">
                {quoteInstalments.map(QuoteValue)}
                {quoteFees.map(QuoteFee)}
            </OmdsCell>
            <OmdsCell className="omds-mt-7" span="row" span-md="3-10">
                <div className='quote-accordion'>
                    {accordianDetails && <FaqCards faqCardsContent={accordianDetails[0]} />}
                </div>
            </OmdsCell>
            <OmdsCell className="omds-mt-7" span="row" span-md="3-10">
                <span className="body-small-regular">Please note: The amounts reflected on this page are estimates only and a final loan offer (if any) is subject to a full credit assessment that is based on, among other things, your affordability and credit profile.</span>
            </OmdsCell>
        </OmdsGrid>
    );
};

export default QuotePage