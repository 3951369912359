import { useState, useEffect } from 'react';

export const useFieldState = (value) => {
    const [fieldValue, setFieldValue] = useState(value);

    useEffect(() => {
        setFieldValue(value);
    }, [value]);

    function onInput(e) {
        setFieldValue(e.detail);
    }

    return [
        fieldValue,
        onInput
    ];
}
