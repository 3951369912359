import React from 'react';
import { graphql } from 'gatsby';
import { OmFaqCards } from '@om/component-library-react';
import FaqCard from './FaqCard';

const FaqCards = ({
    faqCardsContent,
    slot
}) => {
    if(!faqCardsContent) {
        return '';
    }

    const {
        has_boundary,
        use_heading_as_h1,
        top_line,
        heading,
        sub_title,
        caption,
        accordion_items,
        button_text,
        button_url,
        button_action_type,
        call_me_back,
    } = faqCardsContent;

    return (
        <OmFaqCards
            slot={slot}
            hasBoundary={has_boundary}
            buttonText={button_text ? button_text : ''}
            buttonUrl={button_url ? button_url.href : ''}
            buttonActionType={button_action_type}
            callMeBackObject={call_me_back ? JSON.stringify(call_me_back) : ''}
        >
            {
                top_line ? <span slot="top-line" dangerouslySetInnerHTML={{__html: top_line}} /> : ''
            }
            {
                heading ?
                    (use_heading_as_h1 ?
                        <h1 slot="heading" dangerouslySetInnerHTML={{__html: heading}} />
                        : <span slot="heading" dangerouslySetInnerHTML={{__html: heading}} /> )
                    : ''
            }
            {
                sub_title ? <span slot="sub-title" dangerouslySetInnerHTML={{__html: sub_title}} /> : ''
            }
            {
                caption ? <span slot="caption" dangerouslySetInnerHTML={{__html: caption}}/> : ''
            }
            {
                accordion_items ?
                    <ul slot="accordion-items">
                        {
                            accordion_items.map((itemEntry, index) => {
                                return (
                                    <FaqCard
                                        key={index}
                                        faqCardContent={itemEntry}/>
                                )
                            })
                        }
                    </ul> : ''
            }
        </OmFaqCards>
    );
};

export const pageQuery = graphql`


fragment FaqCards on Contentstack_faq_cards {
  	uid
  	has_boundary
    use_heading_as_h1
  	top_line
  	heading
  	sub_title
  	caption
  	accordion_items {
    	...FaqCard
  	}
  	button_text
  	button_url {
    	...Link
  	}
  	button_action_type
    call_me_back {
        ...CallMeBack
    }
}
`;

export default FaqCards
