import { useEffect, useState } from 'react';
import { fetchBlackBoxIOData, getLatestBBDataIfAvailable } from './iovation-config';

export const useBlackBoxDataFetcher = onBlackBoxDataChange => {
    const [bbData, setBBData] = useState('');
    let isMounted = true;

    const updateBBData = data => {
        onBlackBoxDataChange(data);
        setBBData(() => data);
    }

    useEffect(() => {

        const latestBBDataArrived = getLatestBBDataIfAvailable();
        if(latestBBDataArrived !== null){
            updateBBData(latestBBDataArrived);
        }

        const fetcher = async () => {
            const data = await fetchBlackBoxIOData();
            if (isMounted) {
                updateBBData(data)
            }
        }

        fetcher();

        // This is expected as the iovation loader run asynchronously and fires
        // randomly. It also doesn't "always" fire if it has detected conditions
        // that cause the same BB data to be generated. Hence we need the "isMounted"
        // not to be saved across renders and drop updates if they fire outside render
        // loop. The linter does not pickup this condition correctly, so disable.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => isMounted = false;
    }, [bbData]);
}