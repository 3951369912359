
const blackBoxData = {
  resolver: null,
  data: null
};

export function fetchBlackBoxIOData() {
  return new Promise(resolve => {
      blackBoxData.resolver = resolve;
  });
}

export function getLatestBBDataIfAvailable() {
  return blackBoxData.data;
}


(function() {
  const isSSR = typeof window === 'undefined';
  if (isSSR) return;

  window.io_global_object_name = 'IGLOO';
  window.IGLOO = window.IGLOO || {
      enable_flash: false,
      loader : {
          version : 'general5',
          subkey : 'UqpgvDnF99jQfrhSaSpXQpwmVVsfg3DWPUtcuinvDsY'
      },
      bb_callback: function(bb, complete) {
          if (complete) {
              blackBoxData.data = bb;
              if (blackBoxData.resolver) {
                  blackBoxData.resolver(bb);
              }
          }
      }
  };

  import('./iovation-loader');
})();
