import React from 'react';
import {useScript} from '../../hooks/recaptcha';

const recaptchaSiteKey = process.env.GATSBY_RECAPTCHA;

const ReCaptcha = ({
    slot,
    expiredHandler,
    errorHandler,
    verifyHandler
}) => {

    if (typeof window !== 'undefined') {
        window.onRecaptchaLoad = function () {
            try {
                window.grecaptcha.render('om-recaptcha', {
                    'sitekey': recaptchaSiteKey,
                    'callback': verifyHandler,
                    'expired-callback': expiredHandler,
                    'error-callback': errorHandler
                });
            } catch (error) {
                /*possible duplicated instances*/
                            }
        };
    }

    useScript('https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit');

    return (
        <div slot={slot} id="om-recaptcha"></div>
    );
};

export function resetRecaptcha() {
    window.grecaptcha.reset();
};

export default ReCaptcha;
