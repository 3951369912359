import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { useFieldState } from '../useFieldState';
import { OmdsCell, OmdsGrid, OmdsDropdownField, OmdsDropdownOption, OmdsRadioGroup, OmdsRadioField, OmdsDatePickerField } from '@mom-omds/react-design-system-components/dist/generated/components';
import { BANK_NAME, EMPLOYMENT_CONTRACT_END, EMPLOYMENT_START_DATE, EMPLOYMENT_STATUS, HAS_MEDICAL_CONDITIONS, DEBT_LIABILITY, RETRENCHMENT, SECTOR } from '@om/redux/actions/digitalOnboarding/types';
import { isContractEmployment } from '@om/redux/selectors/digitalOnboarding/employment';
import { useDispatch, useSelector } from 'react-redux';
import { storeValue } from '@om/redux/actions/digitalOnboarding/employmentDetails';
import '../common.scss';

const EmploymentDetails = () => {
    const dispatch = useDispatch();
    const storeValues = useSelector(state => state.digitalOnboarding.employmentDetails.storeValues);
    const lookUpData = useSelector(state => state.digitalOnboarding.lookupData.data);
    const { contractEmployment } = useSelector(createStructuredSelector({ contractEmployment: isContractEmployment}));
    const sectors = lookUpData.employmentSector;
    const employmentStatuses = lookUpData.employmentStatus;
    const listOfBanks = lookUpData.bank;
    const debtLiabilityList = lookUpData.debtLiability;

    const [sectorValue, onSector] = useFieldState(storeValues[SECTOR].value);
    const [employmentStatusValue, onEmploymentStatus] = useFieldState(storeValues[EMPLOYMENT_STATUS].value);
    const [employmentStartDateValue, onEmploymentStartDate] = useFieldState(storeValues[EMPLOYMENT_START_DATE].value);
    const [contractEndDateValue, onContractEndDate] = useFieldState(storeValues[EMPLOYMENT_CONTRACT_END].value);
    const [bankValue, onBank] = useFieldState(storeValues[BANK_NAME].value);
    const [debtLiabilityValue, onDebtLiability] = useFieldState(storeValues[DEBT_LIABILITY].value);
    const [retrenchmentValue, onRetrenchment] = useFieldState(storeValues[RETRENCHMENT].value);
    const [medicalConditionValue, onMedicalCondition] = useFieldState(storeValues[HAS_MEDICAL_CONDITIONS].value);
    const applicationValues = useSelector(state => state.digitalOnboarding.applicationNow);

    useEffect(() => {
        dataLayer.push({
            "app": "omflending",
            "applicationNumber": applicationValues.applicationNumber,
            "applicationOutcome": applicationValues.applicationOutcome,
            "environment": process.env.GATSBY_ENVIRONMENT_NAME,
            "event": window.location.pathname.split('/')[3],
            "eventOutcome": "",
            "page": window.location.pathname.split('/')[3]
        });
    }, []);

    const getSectors = () => {
        return sectors?.map((element) => <OmdsDropdownOption isSelected={sectorValue === element.value} label={element.description} value={element.value} key={element.value}>{element.description}</OmdsDropdownOption>)
    }
    const getEmploymentStatus = () => {
        return employmentStatuses?.map((element) => <OmdsDropdownOption isSelected={employmentStatusValue === element.value} label={element.description} value={element.value} key={element.value}>{element.description}</OmdsDropdownOption>)
    }
    const getListOfBanks = () => {
        return listOfBanks?.map((element) => <OmdsDropdownOption isSelected={bankValue === element.value} label={element.description} value={element.value} key={element.value}>{element.description}</OmdsDropdownOption>)
    }
    const getDebtLiabilityList = () => {
        return debtLiabilityList?.map((element) => <OmdsDropdownOption isSelected={debtLiabilityValue === element.value} label={element.description} value={element.value} key={element.value}>{element.description}</OmdsDropdownOption>)
    }

    const getContractEndDate = () => (
        <OmdsDatePickerField
            label={'Contract end date'}
            value={contractEndDateValue}
            onPersist={onContractEndDate}
            onBlur={() => dispatch(storeValue(EMPLOYMENT_CONTRACT_END, contractEndDateValue))}
            hasError={storeValues[EMPLOYMENT_CONTRACT_END].errorMessage !== ''}>
            <span slot="hint-text">{storeValues[EMPLOYMENT_CONTRACT_END].errorMessage}</span>
        </OmdsDatePickerField>
    );

    const handleRadioButton = (key,value) =>{
        dispatch(storeValue(key,value));
    }

    return (
        <OmdsGrid>
            <OmdsCell className="omds-mt-12 heading-text" span="row" spanMd="2..">
                <span className="heading-font h4-light">
                    Employment
                </span>
            </OmdsCell>
            <OmdsCell className="omds-mt-1" span="row" spanMd="2..">
                <p className="subtitle-text">Please provide us with your employment information</p>
            </OmdsCell>
            <OmdsCell className="omds-mt-1" span="row" span-md="2..">
                <p className="subtitle-text">Asterisk (*) fields are mandatory</p>
            </OmdsCell>
            <OmdsCell className="omds-mt-7 omds-c-flex omds-gap-7" span="row" spanMd="3-10">
                <OmdsDropdownField
                    label="Which sector do you work in? *"
                    onPersist={onSector}
                    onBlur={()=>dispatch(storeValue(SECTOR,sectorValue))}
                    hasError={storeValues[SECTOR].errorMessage !== ''}>
                    {getSectors()}
                    <span slot="hint-text">{storeValues[SECTOR].errorMessage}</span>
                </OmdsDropdownField>
                <OmdsDropdownField
                    label="Employment status *"
                    onPersist={onEmploymentStatus}
                    onBlur={()=>dispatch(storeValue(EMPLOYMENT_STATUS,employmentStatusValue))}
                    hasError={storeValues[EMPLOYMENT_STATUS].errorMessage !== ''}>
                    {getEmploymentStatus()}
                    <span slot="hint-text">{storeValues[EMPLOYMENT_STATUS].errorMessage}</span>
                </OmdsDropdownField>
                <OmdsDatePickerField
                    label={contractEmployment ? 'Contract start date *' : 'Employment start date *'}
                    value={employmentStartDateValue}
                    onPersist={onEmploymentStartDate}
                    onBlur={() => dispatch(storeValue(EMPLOYMENT_START_DATE, employmentStartDateValue))}
                    hasError={storeValues[EMPLOYMENT_START_DATE].errorMessage !== ''}>
                    <span slot="hint-text">{storeValues[EMPLOYMENT_START_DATE].errorMessage}</span>
                </OmdsDatePickerField>
                {contractEmployment && getContractEndDate()}
                <OmdsDropdownField
                    label="Who do you bank with? *"
                    onPersist={onBank}
                    onBlur={()=>dispatch(storeValue(BANK_NAME,bankValue))}
                    hasError={storeValues[BANK_NAME].errorMessage !== ''}>
                    {getListOfBanks()}
                    <span slot="hint-text">{storeValues[BANK_NAME].errorMessage}</span>
                </OmdsDropdownField>
                <OmdsDropdownField
                    label="Are you under debt review, administration or insolvent? *"
                    onPersist={onDebtLiability}
                    onBlur={()=>dispatch(storeValue(DEBT_LIABILITY,debtLiabilityValue))}
                    hasError={storeValues[DEBT_LIABILITY].errorMessage !== ''}>
                    {getDebtLiabilityList()}
                    <span slot="hint-text">{storeValues[DEBT_LIABILITY].errorMessage}</span>
                </OmdsDropdownField>
                <OmdsRadioGroup  onPersist={onRetrenchment} >
                    <span className="subtitle-small-regular " slot="title">Are you currently aware of any pending  retrenchments at your employer? *</span>
                    <div className="omds-gap-m-6 omds-r-flex omds-c-flex-m omds-gap-7">
                        <OmdsRadioField onClick={()=>handleRadioButton(RETRENCHMENT,retrenchmentValue)} checked={retrenchmentValue === 'true'} value={true}>Yes</OmdsRadioField>
                        <OmdsRadioField onClick={()=>handleRadioButton(RETRENCHMENT,retrenchmentValue)} checked={retrenchmentValue === 'false'} value={false}>No</OmdsRadioField>
                    </div>
                </OmdsRadioGroup>
                <OmdsRadioGroup  onPersist={onMedicalCondition}>
                    <span className="subtitle-small-regular " slot="title">Do you have any medical conditions that may result in you not being able to repay the loan? *</span>
                    <div className="omds-gap-m-6 omds-r-flex omds-c-flex-m omds-gap-7">
                        <OmdsRadioField onClick={()=>handleRadioButton(HAS_MEDICAL_CONDITIONS,medicalConditionValue)} checked={medicalConditionValue === 'true'} value={true}>Yes</OmdsRadioField>
                        <OmdsRadioField onClick={()=>handleRadioButton(HAS_MEDICAL_CONDITIONS,medicalConditionValue)} checked={medicalConditionValue === 'false'} value={false}>No</OmdsRadioField>
                    </div>
                </OmdsRadioGroup>
            </OmdsCell>
        </OmdsGrid>
    );
}
export default EmploymentDetails