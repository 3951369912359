import React from 'react';
import { useDispatch } from 'react-redux';
import { resetApplicationNowResponse, setReturnToProduct } from '@om/redux/actions/digitalOnboarding/routing';
import { OmModal } from '@om/component-library-react';
import { OmdsButton, OmdsIcon } from '@mom-omds/react-design-system-components';
import OnboardingErrorModal from '../OnboardingErrorModal';

const StaffModal = ({shouldOpen}) => {
    const dispatch = useDispatch();

    const backToLoans = () => dispatch(setReturnToProduct());

    return <OmModal
        open={shouldOpen}
        isCentered="true"
        overlayName="onboarding-error-modal"
        className="onboarding-error-modal"
        onOnClosed={() => dispatch(resetApplicationNowResponse())}
    >
        <div slot="modal-content" className="cancel-modal">
            <div className="onboarding-error-modal-content">
                <OmdsIcon className="icon" size={100} name="error" />
                <span className="h4-light">Old Mutual Finance Staff Member</span>
                <span className="body-regular">
                    Our records indicate that you are an OMF staff member. Please send an email to <a href='mailto:OMFStaffLoans@oldmutual.com'>OMFStaffLoans@oldmutual.com</a> for OMF staff personal loan applications.
                </span>
            </div>
            <div className="button-group">
                <OmdsButton primary iconName="arrow-forward" onClick={backToLoans}>BACK TO LOANS</OmdsButton>
            </div>
        </div>
    </OmModal>

}
export default StaffModal;