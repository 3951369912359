import React, { useEffect } from 'react';
import { OmdsCell, OmdsGrid, OmdsTextField, OmdsNumberField, OmdsCheckboxField } from '@mom-omds/react-design-system-components/dist/generated/components';
import { FIRST_NAME, LAST_NAME, EMAIL_ADDRESS, ID_NUMBER, CELL_NUMBER, CREDIT_CONSENT, PERSONAL_CONSENT, RECAPTCHA, BLACK_BOX_DATA } from '@om/redux/actions/digitalOnboarding/types';
import ActiveQuoteModal from './ActiveQuoteModal';
import StaffModal from './StaffModal';
import CreditDeclineModal from './CreditDeclineModal';
import '../common.scss';
import { useFieldState } from '../useFieldState';
import { useDispatch, useSelector } from 'react-redux';
import { storeValue } from '@om/redux/actions/digitalOnboarding/personalDetails';
import { resetOTPValue }  from '@om/redux/actions/digitalOnboarding/otp';
import ReCaptcha from '../../../components/forms/ReCaptcha';
import { useBlackBoxDataFetcher } from './lib/iovation.js';

const PersonalDetailsPage = () => {
    const storeValues = useSelector(state => state.digitalOnboarding.personalDetails.storeValues);
    const applicationNow = useSelector(state => state.digitalOnboarding.applicationNow);
    const applicantHadActiveApplication = useSelector(state => !state.digitalOnboarding.applicationNow.proceedWithApplication && state.digitalOnboarding.applicationNow.applicationOutcome === 'ACTIVE_QUOTE');
    const applicantIsOMFStaff = useSelector(state => !state.digitalOnboarding.applicationNow.proceedWithApplication && state.digitalOnboarding.applicationNow.applicationOutcome === 'OMF_STAFF');
    const applicantIsCreditDeclined = useSelector(state => !state.digitalOnboarding.applicationNow.proceedWithApplication && state.digitalOnboarding.applicationNow.applicationOutcome === 'CREDIT_DECLINE');
    const dispatch = useDispatch();

    const [firstNameValue, onFirstName] = useFieldState(storeValues[FIRST_NAME].value);
    const [lastNameValue, onLastName] = useFieldState(storeValues[LAST_NAME].value);
    const [iDValue, onIDNumber] = useFieldState(storeValues[ID_NUMBER].value);
    const [cellValue, onCellNumber] = useFieldState(storeValues[CELL_NUMBER].value);
    const [emailValue , onEmail] = useFieldState(storeValues[EMAIL_ADDRESS].value);
    const [creditConsentValue, onCreditConsent] = useFieldState(storeValues[CREDIT_CONSENT].value);
    const [personalConsentValue, onPersonalConsent] = useFieldState(storeValues[PERSONAL_CONSENT].value);

    useEffect(() => {
        dispatch(storeValue(RECAPTCHA, null));
        dispatch(resetOTPValue());
    }, [dispatch]);

    useBlackBoxDataFetcher(data => dispatch(storeValue(BLACK_BOX_DATA, data)));

    return (
        <OmdsGrid>
            <OmdsCell className="heading-text" span="row" span-md="2..">
                <span className="heading-font h4-light">
                    Let’s get to know you
                </span>
            </OmdsCell>
            <OmdsCell className="omds-mt-1" span="row" span-md="2..">
                <p className="subtitle-text">Please provide us with your personal details</p>
            </OmdsCell>
            <OmdsCell className="omds-mt-1" span="row" span-md="2..">
                <p className="subtitle-text">Asterisk (*) fields are mandatory</p>
            </OmdsCell>
            <OmdsCell className="omds-mt-7 omds-c-flex omds-gap-7" span="row" span-md="3-10">
                <OmdsTextField
                    label="Full Names (as per ID) *"
                    value={firstNameValue}
                    onPersist={onFirstName}
                    maxLength={50}
                    onBlur={() => dispatch(storeValue(FIRST_NAME, firstNameValue))}
                    hasError={storeValues[FIRST_NAME].errorMessage !== ''}>
                    <span slot="hint-text">{storeValues[FIRST_NAME].errorMessage}</span>
                </OmdsTextField>

                <OmdsTextField
                    label="Surname *"
                    value={lastNameValue}
                    onPersist={onLastName}
                    maxLength={50}
                    onBlur={() => dispatch(storeValue(LAST_NAME, lastNameValue))}
                    hasError={storeValues[LAST_NAME].errorMessage !== ''} >
                    <span slot="hint-text">{storeValues[LAST_NAME].errorMessage}</span>
                </OmdsTextField>

                <OmdsNumberField
                    label="ID Number *"
                    value={iDValue}
                    onPersist={onIDNumber}
                    maxLength={13}
                    onBlur={() => dispatch(storeValue(ID_NUMBER, iDValue))}
                    hasError={storeValues[ID_NUMBER].errorMessage !== ''}>
                    <span slot="hint-text">{storeValues[ID_NUMBER].errorMessage}</span>
                </OmdsNumberField>

                <OmdsTextField
                    label="Cellphone Number *"
                    value={cellValue}
                    onPersist={onCellNumber}
                    maxLength={12}
                    onBlur={() => dispatch(storeValue(CELL_NUMBER, cellValue))}
                    hasError={storeValues[CELL_NUMBER].errorMessage !== ''}>
                    <span slot="hint-text">{storeValues[CELL_NUMBER].errorMessage === '' ? 'Please note that this will be the number for OTP verification.' : storeValues[CELL_NUMBER].errorMessage}</span>
                </OmdsTextField>

                <OmdsTextField
                    label="Email Address *"
                    value={emailValue}
                    onPersist={onEmail}
                    onBlur={() => dispatch(storeValue(EMAIL_ADDRESS, emailValue))}
                    hasError={storeValues[EMAIL_ADDRESS].errorMessage !== ''}>
                    <span slot="hint-text">{storeValues[EMAIL_ADDRESS].errorMessage}</span>
                </OmdsTextField>

                <div>
                    <OmdsCheckboxField
                        onPersist={onCreditConsent}
                        checked={creditConsentValue}
                        onClick={() => dispatch(storeValue(CREDIT_CONSENT, creditConsentValue))}>
                        Do you consent to us to doing a credit check?
                    </OmdsCheckboxField>
                    <span className={storeValues[CREDIT_CONSENT].errorMessage === '' ? 'omds-hidden' : 'caption-medium omds-error-text omds-mt-3'}>
                        {storeValues[CREDIT_CONSENT].errorMessage}
                    </span>
                </div>

                <div>
                    <OmdsCheckboxField
                        onPersist={onPersonalConsent}
                        checked={personalConsentValue}
                        onClick={() => dispatch(storeValue(PERSONAL_CONSENT, personalConsentValue))}>
                        I consent that Old Mutual Finance (RF) (Pty) Ltd may collect and process my personal information to provide me with the service or product that I am applying for and to meet its related responsibilities under that contract, as well as for other ordinary business purposes, in accordance with the Old Mutual Privacy Notice and applicable laws.
                    </OmdsCheckboxField>
                    <span className={storeValues[PERSONAL_CONSENT].errorMessage === '' ? 'omds-hidden' : 'caption-medium omds-error-text omds-mt-3'}>
                        {storeValues[PERSONAL_CONSENT].errorMessage}
                    </span>
                </div>


                <div>
                    <span className="body-small-regular">
                        Please read our {' '}
                    </span>
                    <a className="text-link-medium" href="/privacy-notice/" target="_blank">
                        Privacy Policy
                    </a>
                </div>
                <ReCaptcha
                    verifyHandler={value => dispatch(storeValue(RECAPTCHA, value))}
                    errorHandler={() => dispatch(storeValue(RECAPTCHA, null))}
                    expiredHandler={() => dispatch(storeValue(RECAPTCHA, null))}
                />
            </OmdsCell>
            <OmdsCell>
                <ActiveQuoteModal shouldOpen={applicantHadActiveApplication} branchName={applicationNow.branchName} branchContactNumber={applicationNow.branchTelephone} consultantName={applicationNow.consultantName}></ActiveQuoteModal>
                <StaffModal shouldOpen={applicantIsOMFStaff}></StaffModal>
                <CreditDeclineModal shouldOpen={applicantIsCreditDeclined}></CreditDeclineModal>
            </OmdsCell>
        </OmdsGrid>
    );
}
export default PersonalDetailsPage
