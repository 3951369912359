import React from 'react';
import { OmdsCell, OmdsGrid, OmdsCheckboxField, OmdsCurrencyField } from '@mom-omds/react-design-system-components/dist/generated/components';
import { CAPITAL_NEEDED, COMMUNICATION_AND_SERVICES, GROCERIES, GROSS_INCOME, HOUSING, INFORMATION_CONFIRMATION, INSURANCE_AND_SAVINGS, NET_INCOME, OTHER, SUPPORT_TO_OTHERS, TOTAL_MONTHLY_LIVING_EXPENSE, TRANSPORT, UTILITIES } from '@om/redux/actions/digitalOnboarding/types';
import { useFieldState } from '../useFieldState';
import { useDispatch, useSelector } from 'react-redux';
import { storeValue } from '@om/redux/actions/digitalOnboarding/affordability';
import OfferUnavailableModal from './OfferUnavailableModal';
import DecimalNumberLabel from '../DecimalNumberLabel';

const AffordabilityPage = () => {
    const storeValues = useSelector(state => state.digitalOnboarding.affordabilityDetails.storeValues);
    const applicantIsCreditDeclined = useSelector(state => !state.digitalOnboarding.applicationNow.proceedWithApplication && state.digitalOnboarding.applicationNow.applicationOutcome === 'CREDIT_DECLINE');
    const dispatch = useDispatch();
    const [grossIncome, onGrossIncome] = useFieldState(storeValues[GROSS_INCOME].text);
    const [netIncome, onNetIncome] = useFieldState(storeValues[NET_INCOME].text);
    const [groceries, onGroceries] = useFieldState(storeValues[GROCERIES].text);
    const [utilities, onUtilities] = useFieldState(storeValues[UTILITIES].text);
    const [insuranceAndSavings, onInsuranceAndSavings] = useFieldState(storeValues[INSURANCE_AND_SAVINGS].text);
    const [communicationAndServices, onCommunicationAndServices] = useFieldState(storeValues[COMMUNICATION_AND_SERVICES].text);
    const [transport, onTransport] = useFieldState(storeValues[TRANSPORT].text);
    const [housing, onHousing] = useFieldState(storeValues[HOUSING].text);
    const [supportToOthers, onSupportToOthers] = useFieldState(storeValues[SUPPORT_TO_OTHERS].text);
    const [other, onOther] = useFieldState(storeValues[OTHER].text);
    const [capital, onCapital] = useFieldState(storeValues[CAPITAL_NEEDED].text);
    const [confirmInformation, onConfirmInformation] = useFieldState(storeValues[INFORMATION_CONFIRMATION].text);

    return (
        <OmdsGrid>
            <OmdsCell className="omds-mt-12 heading-text" span="row" spanMd="2..">
                <span className="heading-font h4-light">
                    Affordability
                </span>
            </OmdsCell>
            <OmdsCell className="omds-mt-1" span="row" spanMd="2..">
                <p className="subtitle-text">Please provide us with your affordability and employment information</p>
            </OmdsCell>
            <OmdsCell className="omds-mt-1" span="row" span-md="2..">
                <p className="subtitle-text">Asterisk (*) fields are mandatory</p>
            </OmdsCell>
            <OmdsCell className="omds-mt-7 omds-c-flex omds-gap-7" span="row" span-md="3-10">
                <OmdsCurrencyField
                    label="Gross income (before deductions) *"
                    value={grossIncome}
                    onPersist={onGrossIncome}
                    onBlur={() => dispatch(storeValue(GROSS_INCOME, grossIncome))}
                    hasError={storeValues[GROSS_INCOME].errorMessage !== ''}>
                    <span slot="hint-text">{storeValues[GROSS_INCOME].errorMessage}</span>
                </OmdsCurrencyField>
                <OmdsCurrencyField
                    label="Net income (after deductions) *"
                    value={netIncome}
                    onPersist={onNetIncome}
                    onBlur={() => dispatch(storeValue(NET_INCOME, netIncome))}
                    hasError={storeValues[NET_INCOME].errorMessage !== ''}>
                    <span slot="hint-text">{storeValues[NET_INCOME].errorMessage}</span>
                </OmdsCurrencyField>
                <span className="overline-eleven-uppercase-medium">
                    Current Monthly Living Expenses *
                </span>
                <span className={storeValues[TOTAL_MONTHLY_LIVING_EXPENSE].errorMessage === '' ? 'omds-hidden' : 'caption-medium omds-error-text omds-mt-1'}>
                    {storeValues[TOTAL_MONTHLY_LIVING_EXPENSE].errorMessage}
                </span>
                <OmdsCurrencyField
                    label="Food and Groceries"
                    value={groceries}
                    onPersist={onGroceries}
                    onBlur={() => dispatch(storeValue(GROCERIES, groceries))}
                    hasError={storeValues[GROCERIES].errorMessage !== ''}>
                    <span slot="hint-text">{storeValues[GROCERIES].errorMessage}</span>
                </OmdsCurrencyField>
                <OmdsCurrencyField
                    label="Utilities (water, lights, rates, taxes, levies)"
                    value={utilities}
                    onPersist={onUtilities}
                    onBlur={() => dispatch(storeValue(UTILITIES, utilities))}
                    hasError={storeValues[UTILITIES].errorMessage !== ''}>
                    <span slot="hint-text">{storeValues[UTILITIES].errorMessage}</span>
                </OmdsCurrencyField>
                <OmdsCurrencyField
                    label="Insurance & Savings (short term, long term, stokvel, savings products, investments)"
                    value={insuranceAndSavings}
                    onPersist={onInsuranceAndSavings}
                    onBlur={() => dispatch(storeValue(INSURANCE_AND_SAVINGS, insuranceAndSavings))}
                    hasError={storeValues[INSURANCE_AND_SAVINGS].errorMessage !== ''}>
                    <span slot="hint-text">{storeValues[INSURANCE_AND_SAVINGS].errorMessage}</span>
                </OmdsCurrencyField>
                <OmdsCurrencyField
                    label="Communication & Services (cellphone, home phone, internet, DStv)"
                    value={communicationAndServices}
                    onPersist={onCommunicationAndServices}
                    onBlur={() => dispatch(storeValue(COMMUNICATION_AND_SERVICES, communicationAndServices))}
                    hasError={storeValues[COMMUNICATION_AND_SERVICES].errorMessage !== ''}>
                    <span slot="hint-text">{storeValues[COMMUNICATION_AND_SERVICES].errorMessage}</span>
                </OmdsCurrencyField>
                <OmdsCurrencyField
                    label="Transport (vehicle costs, fuel, public transport, taxi)"
                    value={transport}
                    onPersist={onTransport}
                    onBlur={() => dispatch(storeValue(TRANSPORT, transport))}
                    hasError={storeValues[TRANSPORT].errorMessage !== ''}>
                    <span slot="hint-text">{storeValues[TRANSPORT].errorMessage}</span>
                </OmdsCurrencyField>
                <OmdsCurrencyField
                    label="Housing/accommodation"
                    value={housing}
                    onPersist={onHousing}
                    onBlur={() => dispatch(storeValue(HOUSING, housing))}
                    hasError={storeValues[HOUSING].errorMessage !== ''}>
                    <span slot="hint-text">{storeValues[HOUSING].errorMessage}</span>
                </OmdsCurrencyField>
                <OmdsCurrencyField
                    label="Support to others"
                    value={supportToOthers}
                    onPersist={onSupportToOthers}
                    onBlur={() => dispatch(storeValue(SUPPORT_TO_OTHERS, supportToOthers))}
                    hasError={storeValues[SUPPORT_TO_OTHERS].errorMessage !== ''}>
                    <span slot="hint-text">{storeValues[SUPPORT_TO_OTHERS].errorMessage}</span>
                </OmdsCurrencyField>
                <OmdsCurrencyField
                    label="Other (clothing accounts, entertainment etc.)"
                    value={other}
                    onPersist={onOther}
                    onBlur={() => dispatch(storeValue(OTHER, other))}
                    hasError={storeValues[OTHER].errorMessage !== ''}>
                    <span slot="hint-text">{storeValues[OTHER].errorMessage}</span>
                </OmdsCurrencyField>
                <div className="omds-c-flex omds-gap-4">
                    <span className="subtitle-small-medium">
                        Total Monthly Living Expense
                    </span>
                    <span className="h4-medium">
                        <DecimalNumberLabel prefix="R " value={storeValues[TOTAL_MONTHLY_LIVING_EXPENSE].text} suffix=" p/m"></DecimalNumberLabel>
                    </span>
                    <span className={storeValues[TOTAL_MONTHLY_LIVING_EXPENSE].errorMessage === '' ? 'omds-hidden' : 'caption-medium omds-error-text omds-mt-3'}>
                        {storeValues[TOTAL_MONTHLY_LIVING_EXPENSE].errorMessage}
                    </span>
                </div>
                <OmdsCurrencyField
                    label="How much money do you need? *"
                    value={capital}
                    onPersist={onCapital}
                    onBlur={() => dispatch(storeValue(CAPITAL_NEEDED, capital))}
                    hasError={storeValues[CAPITAL_NEEDED].errorMessage !== ''}>
                    <span slot="hint-text">{storeValues[CAPITAL_NEEDED].errorMessage}</span>
                </OmdsCurrencyField>
                <div>
                    <OmdsCheckboxField
                        onPersist={onConfirmInformation}
                        checked={confirmInformation}
                        onClick={() => dispatch(storeValue(INFORMATION_CONFIRMATION, confirmInformation, true))}>
                        I confirm that all information provided in connection with this loan application (including your income and expenses declared above) is true and complete *
                    </OmdsCheckboxField>
                    <span className={storeValues[INFORMATION_CONFIRMATION].errorMessage === '' ? 'omds-hidden' : 'caption-medium omds-error-text omds-mt-3'}>
                        {storeValues[INFORMATION_CONFIRMATION].errorMessage}
                    </span>
                </div>
            </OmdsCell>
            <OmdsCell>
                <OfferUnavailableModal shouldOpen={applicantIsCreditDeclined}></OfferUnavailableModal>
            </OmdsCell>
        </OmdsGrid>
    );
}
export default AffordabilityPage
