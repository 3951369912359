import React from 'react';
import { useDispatch } from 'react-redux';
import { resetApplicationNowResponse, setReturnToProduct } from '@om/redux/actions/digitalOnboarding/routing';
import { OmdsIcon } from '@mom-omds/react-design-system-components';
import { OmModal, OmButton } from '@om/component-library-react';

const CreditDeclineModal = ({shouldOpen}) => {
    const dispatch = useDispatch();

    const backToLoans = () => dispatch(setReturnToProduct());
    const resetAndBackToLoans = () => {
        dispatch(resetApplicationNowResponse());
        dispatch(setReturnToProduct())
    };

    return <OmModal
        open={shouldOpen}
        isCentered="true"
        overlayName="credit-decline-modal"
        dismissible
        className="credit-decline-modal"
        onOnClosed={resetAndBackToLoans}
    >
        <div slot="modal-content">
            <div className="onboarding-error-modal-content">
                <OmdsIcon className="icon" size={100} name="error" />
                <span className="h4-light">Thank you for your interest in an Old Mutual Finance loan.</span>
                <span className="body-regular">
                    Unfortunately, we cannot offer you a loan right now as you do not meet our current lending criteria. We would like to offer you a loan in future, so do not hesitate to contact us on 0860 000 886.
                </span>
                <div className="credit-decline-modal-buttons">
                    <OmButton
                        type="primary"
                        text="OK"
                        size="large"
                        onClick={resetAndBackToLoans}
                    />
                </div>
            </div>
        </div>
    </OmModal>
}
export default CreditDeclineModal;