import React, { useState } from 'react';
import { OmdsCell, OmdsGrid, OmdsTextField, OmdsNumberField, OmdsRadioGroup, OmdsRadioField, OmdsDropdownOption, OmdsDropdownField } from '@mom-omds/react-design-system-components/dist/generated/components';
import { useFieldState } from '../useFieldState';
import { useDispatch, useSelector } from 'react-redux';
import { ADDRESS_LINE_1, CITY_PHYSICAL, CITY_POSTAL, COMPLEX_BUILDING, POSTAL_CODE_PHYSICAL, POSTAL_CODE_POSTAL, POSTAL_SAME_AS_PHYSICAL, PROVINCE_PHYSICAL, PROVINCE_POSTAL, RURAL_LOW_DENSITY, STREET_ADDRESS, SUBURB_PHYSICAL, SUBURB_POSTAL } from '@om/redux/actions/digitalOnboarding/types';
import { storeValue } from '@om/redux/actions/digitalOnboarding/addressDetails';
import './AddressPage.scss';
import { useScrollRef } from './useScrollRef';

const AddressPage = () => {
    const storeValues = useSelector(state => state.digitalOnboarding.addressDetails.storeValues);
    const lookupData = useSelector(state => state.digitalOnboarding.lookupData.data);
    const provinces = lookupData.province;

    const [provincePhysicalOpen, setProvincePhysical] = useState(false);
    const [provincePostalOpen, setProvincePostal] = useState(false);

    const [complexBuildingValue, onComplexBuilding] = useFieldState(storeValues[COMPLEX_BUILDING].value);
    const [streetAddressValue, onStreetAddress] = useFieldState(storeValues[STREET_ADDRESS].value);
    const [postalCodeValue, onPostalCode] = useFieldState(storeValues[POSTAL_CODE_PHYSICAL].value);
    const [suburbValue, onSuburb] = useFieldState(storeValues[SUBURB_PHYSICAL].value);
    const [cityValue, onCity] = useFieldState(storeValues[CITY_PHYSICAL].value);
    const [provinceValue, onProvince] = useFieldState(storeValues[PROVINCE_PHYSICAL].value);
    const [ruralValue, onRural] = useFieldState(storeValues[RURAL_LOW_DENSITY].value);
    const [postalVsPhysicalValue, onPostalVsPhysical] = useFieldState(storeValues[POSTAL_SAME_AS_PHYSICAL].value);

    const [addressLine1Value, onAddressLine1] = useFieldState(storeValues[ADDRESS_LINE_1].value);
    const [postalPostalCodeValue, onPostalPostalCode] = useFieldState(storeValues[POSTAL_CODE_POSTAL].value);
    const [postalSuburbValue, onPostalSuburb] = useFieldState(storeValues[SUBURB_POSTAL].value);
    const [postalCityValue, onPostalCity] = useFieldState(storeValues[CITY_POSTAL].value);
    const [postalProvinceValue, onPostalProvince] = useFieldState(storeValues[PROVINCE_POSTAL].value);
    const dispatch = useDispatch();

    const dropDownPostalRef = useScrollRef(provincePostalOpen);
    const dropDownPhysicalRef = useScrollRef(provincePhysicalOpen);
    const componentRef = useScrollRef(storeValues[POSTAL_SAME_AS_PHYSICAL].value === 'false', storeValues[POSTAL_SAME_AS_PHYSICAL].value);

    const handleRadioButton = (key, value) => {
        dispatch(storeValue(key, value));
    }

    const getProvinces = (value) => {
        return provinces?.map((element) => <OmdsDropdownOption isSelected={value === element.value} label={element.description} value={element.value} key={element.value}>{element.description}</OmdsDropdownOption>)
    }

    const onProvincePhysicalFocus = () => {
        setProvincePhysical(true);
    }
    const onProvincePhysicalBlur = () => {
        setProvincePhysical(false);
        dispatch(storeValue(PROVINCE_PHYSICAL, provinceValue));
    }

    const onProvincePostalFocus = () => {
        setProvincePostal(true);
    }
    const onProvincePostalBlur = () => {
        setProvincePostal(false);
        dispatch(storeValue(PROVINCE_POSTAL, postalProvinceValue));
    }

    return (
        <OmdsGrid>
            <OmdsCell className="omds-mt-12 heading-text" span="row" spanMd="2..">
                <span className="heading-font h4-light">Address</span>
            </OmdsCell>
            <OmdsCell className="omds-mt-1" span="row" spanMd="2..">
                <p className="subtitle-text">Please share your address details</p>
            </OmdsCell>
            <OmdsCell className="omds-mt-1" span="row" span-md="2..">
                <p className="subtitle-text">Asterisk (*) fields are mandatory</p>
            </OmdsCell>
            <OmdsCell className={`${provincePhysicalOpen && 'omds-mb-12'} omds-mt-7 omds-c-flex omds-gap-7`} span="row" span-md="3-10">
                <span className="overline-eleven-uppercase-medium">
                    Physical Address
                </span>
                <OmdsTextField
                    label="Complex/building"
                    value={complexBuildingValue}
                    onPersist={onComplexBuilding}
                    maxLength={30}
                    onBlur={() => dispatch(storeValue(COMPLEX_BUILDING, complexBuildingValue))}
                    hasError={storeValues[COMPLEX_BUILDING].errorMessage !== ''}
                >
                    <span slot="hint-text">{storeValues[COMPLEX_BUILDING].errorMessage}</span>
                </OmdsTextField>
                <OmdsTextField
                    label="Street address *"
                    value={streetAddressValue}
                    onPersist={onStreetAddress}
                    maxLength={30}
                    onBlur={() => dispatch(storeValue(STREET_ADDRESS, streetAddressValue))}
                    hasError={storeValues[STREET_ADDRESS].errorMessage !== ''}
                >
                    <span slot="hint-text">{storeValues[STREET_ADDRESS].errorMessage}</span>
                </OmdsTextField>
                <OmdsTextField
                    label="Suburb *"
                    value={suburbValue}
                    onPersist={onSuburb}
                    onBlur={() => dispatch(storeValue(SUBURB_PHYSICAL, suburbValue))}
                    hasError={storeValues[SUBURB_PHYSICAL].errorMessage !== ''}
                >
                    <span slot="hint-text">{storeValues[SUBURB_PHYSICAL].errorMessage}</span>
                </OmdsTextField>
                <OmdsTextField
                    label="City *"
                    value={cityValue}
                    onPersist={onCity}
                    onBlur={() => dispatch(storeValue(CITY_PHYSICAL, cityValue))}
                    hasError={storeValues[CITY_PHYSICAL].errorMessage !== ''}
                >
                    <span slot="hint-text">{storeValues[CITY_PHYSICAL].errorMessage}</span>
                </OmdsTextField>
                <OmdsNumberField
                    label="Postal code *"
                    value={postalCodeValue}
                    onPersist={onPostalCode}
                    maxLength={4}
                    onBlur={() => dispatch(storeValue(POSTAL_CODE_PHYSICAL, postalCodeValue))}
                    hasError={storeValues[POSTAL_CODE_PHYSICAL].errorMessage !== ''}
                >
                    <span slot="hint-text">{storeValues[POSTAL_CODE_PHYSICAL].errorMessage}</span>
                </OmdsNumberField>
                <OmdsDropdownField
                    ref={dropDownPhysicalRef}
                    label="Province *"
                    onPersist={onProvince}
                    onFocus={()=> onProvincePhysicalFocus()}
                    onBlur={() => onProvincePhysicalBlur()}
                    hasError={storeValues[PROVINCE_PHYSICAL].errorMessage !== ''}>
                    {getProvinces(provinceValue)}
                    <span slot="hint-text">{storeValues[PROVINCE_PHYSICAL].errorMessage}</span>
                </OmdsDropdownField>
                <OmdsRadioGroup onPersist={onRural}>
                    <span className="subtitle-small-regular " slot="title">Do you live in a rural/low density area? *</span>
                    <div className="omds-gap-m-6 omds-r-flex omds-c-flex-m omds-gap-7">
                        <OmdsRadioField onClick={() => handleRadioButton(RURAL_LOW_DENSITY, ruralValue)} checked={ruralValue === 'true'} value={true}>Yes</OmdsRadioField>
                        <OmdsRadioField onClick={() => handleRadioButton(RURAL_LOW_DENSITY, ruralValue)} checked={ruralValue === 'false'} value={false}>No</OmdsRadioField>
                    </div>
                </OmdsRadioGroup>
                <OmdsRadioGroup onPersist={onPostalVsPhysical}>
                    <span className="subtitle-small-regular " slot="title">My postal address is different to my physical address *</span>
                    <div className="omds-gap-m-6 omds-r-flex omds-c-flex-m omds-gap-7">
                        <OmdsRadioField onClick={() => handleRadioButton(POSTAL_SAME_AS_PHYSICAL, postalVsPhysicalValue)} checked={postalVsPhysicalValue === 'false'} value={false}>Yes</OmdsRadioField>
                        <OmdsRadioField onClick={() => handleRadioButton(POSTAL_SAME_AS_PHYSICAL, postalVsPhysicalValue)} checked={postalVsPhysicalValue === 'true'} value={true}>No</OmdsRadioField>
                    </div>
                </OmdsRadioGroup>

            </OmdsCell>
            <OmdsCell className={storeValues[POSTAL_SAME_AS_PHYSICAL].value === 'false' ? `${provincePostalOpen && 'drop-down-mega-margin'} omds-mt-7 omds-c-flex omds-gap-7` : 'omds-hidden'} span="row" span-md="3-10">
                <span className="overline-eleven-uppercase-medium">
                    Postal Address
                </span>
                <OmdsTextField
                    ref={componentRef}
                    label="Address line 1 *"
                    value={addressLine1Value}
                    onPersist={onAddressLine1}
                    maxLength={30}
                    onBlur={() => dispatch(storeValue(ADDRESS_LINE_1, addressLine1Value))}
                    hasError={storeValues[ADDRESS_LINE_1].errorMessage !== ''}
                >
                    <span slot="hint-text">{storeValues[ADDRESS_LINE_1].errorMessage}</span>
                </OmdsTextField>
                <OmdsTextField
                    label="Suburb *"
                    value={postalSuburbValue}
                    onPersist={onPostalSuburb}
                    onBlur={() => dispatch(storeValue(SUBURB_POSTAL, postalSuburbValue))}
                    hasError={storeValues[SUBURB_POSTAL].errorMessage !== ''}
                >
                    <span slot="hint-text">{storeValues[SUBURB_POSTAL].errorMessage}</span>
                </OmdsTextField>
                <OmdsTextField
                    label="City *"
                    value={postalCityValue}
                    onPersist={onPostalCity}
                    onBlur={() => dispatch(storeValue(CITY_POSTAL, postalCityValue))}
                    hasError={storeValues[CITY_POSTAL].errorMessage !== ''}
                >
                    <span slot="hint-text">{storeValues[CITY_POSTAL].errorMessage}</span>
                </OmdsTextField>
                <OmdsNumberField
                    label="Postal code *"
                    value={postalPostalCodeValue}
                    onPersist={onPostalPostalCode}
                    maxLength={4}
                    onBlur={() => dispatch(storeValue(POSTAL_CODE_POSTAL, postalPostalCodeValue))}
                    hasError={storeValues[POSTAL_CODE_POSTAL].errorMessage !== ''}
                >
                    <span slot="hint-text">{storeValues[POSTAL_CODE_POSTAL].errorMessage}</span>
                </OmdsNumberField>
                <OmdsDropdownField
                    ref={dropDownPostalRef}
                    label="Province *"
                    onPersist={onPostalProvince}
                    onFocus={() => onProvincePostalFocus()}
                    onBlur={() => onProvincePostalBlur()}
                    hasError={storeValues[PROVINCE_POSTAL].errorMessage !== ''}>
                    {storeValues[POSTAL_SAME_AS_PHYSICAL].value === 'false' && getProvinces(postalProvinceValue)}
                    <span slot="hint-text">{storeValues[PROVINCE_POSTAL].errorMessage}</span>
                </OmdsDropdownField>
            </OmdsCell>
        </OmdsGrid>
    );
}
export default AddressPage