import * as ActionTypes from "./types";
import { validateID, validateCellNumber } from "../../functions/digitalOnboarding/validations";
import { validateEmail } from "@om/validation";

export function storeValue(key, value) {
    const validator = validateField(key, value);
    return {
        type: ActionTypes.STORE_PERSONAL_DETAIL,
        key: key,
        value: value,
        isValid: validator.isValid,
        errorMessage: validator.errorMessage
    };
}

function validateField(question, value) {
    const textValidation = new RegExp(/^!?[^\W\d]([a-zA-Z! `'-]{1,50})[^\W\d]!?$/); 
    switch (question) {
        case ActionTypes.FIRST_NAME:
            const isValidFirstName = textValidation.test(value);
            return {isValid: isValidFirstName, errorMessage: !isValidFirstName ? 'Please enter a valid Name.' : ''}
        case ActionTypes.LAST_NAME:
            const isValidLastName = textValidation.test(value);
            return {isValid: isValidLastName, errorMessage: !isValidLastName ? 'Please enter a valid Surname.' : ''}
        case ActionTypes.ID_NUMBER:
            return validateID(value);
        case ActionTypes.CELL_NUMBER:
            return validateCellNumber(value);
        case ActionTypes.EMAIL_ADDRESS:
            const isValid = validateEmail(value) === null;
            return {isValid: isValid, errorMessage: !isValid ? 'Invalid email address, please enter a valid email address.' : ''};
        case ActionTypes.CREDIT_CONSENT:
            return {isValid: value === 1, errorMessage: value !== 1 ? 'Consent to do credit check needs to be given to proceed with this loan application.' : ''}
        case ActionTypes.PERSONAL_CONSENT:
            return {isValid: value === 1, errorMessage: value !== 1 ? 'Consent to process personal information needs to be given to proceed with this loan application.' : ''}
        case ActionTypes.RECAPTCHA:
            return { isValid: value !== null && value !== undefined && value.trim() !== '', errorMessage: '' };
        default:
            return { isValid: true, errorMessage: '' };
    }
}
