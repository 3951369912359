import React from 'react';
import './LandingCard.scss';
import { OmIcon } from '@om/component-library-react';

const LandingCard = ({ imageUrl, text }) => {
    return <div className="landing-card">
        <OmIcon className="card-images" imageUrl={imageUrl} />
        <span className="mobile-card-text h6-medium">
            {text}
        </span>
    </div>
}

export default LandingCard