import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { OmdsCell, OmdsGrid, OmdsFileUploadFile, OmdsIcon } from '@mom-omds/react-design-system-components/dist/generated/components';
import { scheduleFileForUpload, removeFileFromUpload, cancelFileUpload, retryFileUpload } from '@om/redux/actions/digitalOnboarding/documentStore';
import { getMaxFileSize, getAcceptableFileExtensions, getFileQueue } from '@om/redux/selectors/digitalOnboarding/documentUpload';
import './DocumentPage.scss';
import { OmIcon } from '@om/component-library-react';

const DocumentsPage = () => {
    const [sectionForUpload, setSectionForUpload] = useState(null);
    const dispatch = useDispatch();
    const inputFile = useRef(null);
    const { maxFileSize: { human: humanReadableMaxFileSize }, acceptedFileExtensions, fileQueue } = useSelector(createStructuredSelector({
        maxFileSize: getMaxFileSize,
        acceptedFileExtensions: getAcceptableFileExtensions,
        fileQueue: getFileQueue
    }));

    const documentRequirements = [{
        heading: 'Identity document',
        descriptions: ['Green bar coded Identity Document or ID Card back and front']
    }, {
        heading: 'Bank statements',
        descriptions: ['Bank statement must have a bank stamp', 'Bank statement must reflect the latest 3 salary deposits', 'Bank statement must not be older than 7 days']
    }, {
        heading: 'Latest Payslip / Salary Advice',
        descriptions: ['If the latest payslip reflects a bonus payment, the customer must provide their previous month’s payslip']
    }];

    const openFileBrowser = sectionName => e => {
        setSectionForUpload(() => sectionName);
        inputFile.current.click();
    };

    const handleFilesSelected = e => {
        e.preventDefault();

        dispatch(scheduleFileForUpload(sectionForUpload, Array.from(e.target.files)[0]));
        inputFile.current.value = null;
    }

    const getFileStatusText = file => {
        if (file.file) {
            return file.size;
        }
        return `Max ${humanReadableMaxFileSize}`
    }

    const DocumentRequirements = () => (
        <ol type="1">
            {
                documentRequirements.map(d => (
                    <li key={d.heading} className="body-medium">
                        <span>{d.heading}</span>
                        { d.descriptions.map(description => <p key={description} className="body-regular">{description}</p>) }
                    </li>
                ))
            }
        </ol>
    );

    const cancelFileUploading = sectionName => () => {
        dispatch(cancelFileUpload(sectionName));
    };

    const removeFile = sectionName => () => {
        dispatch(removeFileFromUpload(sectionName));
    };

    const attemptRetryFileUpload = sectionName => () => {
        dispatch(retryFileUpload(sectionName));
    };

    const fileTypeIcons = {
        jpg: 'https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blte591fa1628694be2/630dc2d5b21f130163e53a28/File_Icon_JPG.svg',
        jpeg: 'https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt8cdc11ce1ad62eef/630dc2d56304777e0f86cd5f/File_Icon_JPEG.svg',
        pdf: 'https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/bltd0608b45dc5b4cc4/630dc2d50ac0d86e610e588f/File_Icon_PDF.svg',
        tif:'https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/bltd3792ff6b4d3bcd7/630dc2d5a215667ce2f0e661/File_Icon_TIF.svg',
        tiff:'https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt1218c86c1bc8e768/630dc2d58ec10f096429975a/File_Icon_TIFF.svg',
        png: 'https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt3406354e53973507/630dc2d4693361082e02b2b4/File_Icon_PNG.svg'
    };

    return (
        <OmdsGrid className="document-page">
            <OmdsCell className="heading-text" span="row" spanMd="2..">
                <span className="heading-font">Supporting documents</span>
            </OmdsCell>
            <OmdsCell className="omds-mt-1" span="row" spanMd="2..">
                <p className="subtitle-text">Uploading of supporting documents is not compulsory to submit the application, but essential to fast track your application.</p>
            </OmdsCell>
            <OmdsCell className="omds-mt-7 omds-gap-6 omds-c-flex lending-documents-container" span="row" spanMd="3-10">
                <DocumentRequirements />
                <p>Documents uploaded must be in jpeg, pdf, jpg, png, tif and tiff format and not exceed 5 MB</p>
                <p>Once you submit your application, we’ll conduct a couple of checks and phone you to finalize your loan application</p>
            </OmdsCell>
            <OmdsCell className="omds-mt-7 lending-file-upload-container" span="row" spanMd="3-10">
                <div className="file-upload-container body-regular">
                    <div className="file-upload-notes">
                        <p>Please provide us with copies of the documents required below. Documents should be less than three months old.</p>
                        <p><b>Note:</b> Please ensure the document is not password protected.</p>
                    </div>
                    <hr />
                    <div className="omds-c-flex omds-gap-5">
                        <input type="file" accept={acceptedFileExtensions.join(',')} onInput={handleFilesSelected} className="omds-hidden" ref={inputFile} />
                        {
                            fileQueue.map(file =>
                                <OmdsFileUploadFile
                                    key={file.sectionName}
                                    hasError={file.hasError}
                                    onCancelUpload={cancelFileUploading(file.sectionName)}
                                    onRemove={removeFile(file.sectionName)}
                                    onRetryUploading={attemptRetryFileUpload(file.sectionName)}
                                    onFileClick={openFileBrowser(file.sectionName)}
                                    className="file-uploader-file"
                                >
                                    {
                                        file.file !== null && file.errorMessage === '' && <OmIcon className="file-upload-type-icon" slot='leading-icon' height={40} width={40} imageUrl={fileTypeIcons[file.extension]}/>
                                    }
                                    { file.file?.name ?? file.sectionName }
                                    <div slot='status'>
                                        { getFileStatusText(file) }
                                    </div>
                                    {
                                        file.file === null && <OmdsIcon slot="suffix-icon" size={24} name="file_upload" className="file-upload-icon"></OmdsIcon>
                                    }
                                    <div slot="hint-text">{file.errorMessage}</div>
                                </OmdsFileUploadFile>
                            )
                        }
                    </div>
                </div>
            </OmdsCell>
        </OmdsGrid>
    );
}
export default DocumentsPage