import React from 'react';
import { useDispatch } from 'react-redux';
import { OmApplicationPopupModal, OmIcon } from '@om/component-library-react';
import { OmModal } from '@om/component-library-react';
import { OmdsButton } from '@mom-omds/react-design-system-components';
import { setReturnToProduct, resetApplicationNowResponse } from '@om/redux/actions/digitalOnboarding/routing';

import './ActiveQuoteModal.scss'

const ActiveQuoteModal = ({shouldOpen, branchName, branchContactNumber, consultantName}) => {
    const dispatch = useDispatch();
    const backToLoans = () => dispatch(setReturnToProduct());

    const phoneNumberSplitter = number => number?.toString().replace(/\s/g, '').replace(/(\d{3})(\d{3})(\d)/, '$1 $2 $3');

    return <OmModal
        open={shouldOpen}
        isCentered="true"
        overlayName="active-quote-modal"
        className="active-quote-modal"
        onOnClosed={() => dispatch(resetApplicationNowResponse())}
    >
        <div slot="modal-content" className="cancel-modal">
            <OmIcon imageUrl="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt742bcfb4e8e593f9/6295cffd1acd652232a483df/lending_onboarding_personal_details_modal.svg" />
            <div className="active-loan-contact-group">
                <span className="h5-semi-bold">Our records indicate that you have an existing loan application at:</span>
                <div className="branch-group body-small-regular">
                    <span>{branchName}</span>
                    <span>{phoneNumberSplitter(branchContactNumber)}</span>
                    <span>{consultantName}</span>
                </div>
                <br />
                <span className="body-small-regular">For any further enquires contact the branch.</span>
            </div>
            <div className="button-group">
                <OmdsButton primary iconName="arrow-forward" onClick={backToLoans}>BACK TO LOANS</OmdsButton>
            </div>
        </div>
    </OmModal>
}
export default ActiveQuoteModal;