import {useEffect} from 'react';

export function useScript(scriptSrc) {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = scriptSrc;
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, [scriptSrc]);
};