import React, { useState, useEffect } from 'react';
import { OmdsCell, OmdsGrid, OmdsLink, OmdsOtpField } from '@mom-omds/react-design-system-components/dist/generated/components';
import './OTPPage.scss'
import { useDispatch, useSelector } from 'react-redux';
import { CELL_NUMBER, OTP_VALUE } from '@om/redux/actions/digitalOnboarding/types';
import { storeValue, storeOnblur, resendOTPRequest } from '@om/redux/actions/digitalOnboarding/otp';
import { useFieldState } from '../useFieldState';

const OTPPage = () => {
    const dispatch = useDispatch();
    const phoneNumber = useSelector(state => state.digitalOnboarding.personalDetails.storeValues[CELL_NUMBER].value);
    const otpStoreValues = useSelector((state) => state.digitalOnboarding.otp.storeValues);
    const [otpValue, onOTP] = useFieldState(otpStoreValues[OTP_VALUE].value);
    const [linkDisabled, setLinkDisabled] = useState(false);
    const otpErrorMessage = otpStoreValues[OTP_VALUE].errorMessage;

    const phoneNumberSplitter = number => number?.toString().replace(/\s/g, '').replace(/(.{3})(.{3})/, '$1 $2 ');

    useEffect(()=>{
        setLinkDisabled(true);
    },[]);

    useEffect(() => {
        if (linkDisabled) {
            const timer = setTimeout(() => setLinkDisabled(false), 60000);

            return () => clearTimeout(timer);
        }

    }, [linkDisabled]);

    const handleResend = () => {
        dispatch(resendOTPRequest());
        setLinkDisabled(true);
    }

    const handleBlur = () => {
        if(otpValue === '') {
            dispatch(storeOnblur(OTP_VALUE, otpValue));
        }
    }

    const handlePersist = (e) => {
        onOTP(e);
        dispatch(storeValue(OTP_VALUE, e.detail));
    }

    return (
        <OmdsGrid>
            <OmdsCell className="heading-text" span="row" span-md="2..">
                <span className="heading-font h4-light">
                    OTP Authentification
                </span>
            </OmdsCell>
            <OmdsCell className="otp-card omds-mt-7 omds-c-flex omds-gap-7 omds-jc-center omf-loan-otp-container" span="row" span-md="4-9">
                <span className="h5-medium">
                    Enter your one-time pin (OTP)
                </span>
                <div>
                    <hr></hr>
                </div>
                <span className="body-regular">
                    A one-time PIN (OTP) has been sent to {phoneNumberSplitter(phoneNumber)}. The code is valid for 5 minutes.
                </span>
                <OmdsOtpField
                    className='omf-loan-otp-field'
                    value={otpValue}
                    onBlur={(e)=> handleBlur(e)}
                    has-error={otpErrorMessage !== ''}
                    onPersist={(e)=>handlePersist(e)}
                    input-count="6">
                    <span slot="hint-text">{otpErrorMessage === '' ? 'Enter OTP' : otpErrorMessage}</span>
                </OmdsOtpField>
                <span className={`body-small-regular omds-flex link-text ${linkDisabled && 'omds-hidden'}`}>
                    Didn’t receive the OTP? <OmdsLink regular onClick={() => handleResend()}>Resend</OmdsLink>
                </span>
            </OmdsCell>
        </OmdsGrid>
    );
}
export default OTPPage