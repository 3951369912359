import React, { useEffect } from 'react';
import { OmdsButton, OmdsCell, OmdsGrid, OmdsIcon } from '@mom-omds/react-design-system-components/dist/generated/components';
import './SuccessPage.scss'
import { useDispatch, useSelector } from 'react-redux';
import { setReturnToProduct } from '@om/redux/actions/digitalOnboarding/routing';
import { getApplicationNumber, getApplicationOutcome } from '@om/redux/selectors/digitalOnboarding/application';
import { FIRST_NAME } from '@om/redux/actions/digitalOnboarding/types';

const SuccessPage = () => {
    const dispatch = useDispatch();
    const backToLoans = () => dispatch(setReturnToProduct());
    const storeValues = useSelector(state => state.digitalOnboarding.personalDetails.storeValues);
    const applicationValues = useSelector(state => state.digitalOnboarding.applicationNow);

    useEffect(() => {
        dataLayer.push({
            "app": "omflending",
            "applicationNumber": applicationValues.applicationNumber,
            "applicationOutcome": applicationValues.applicationOutcome,
            "environment": process.env.GATSBY_ENVIRONMENT_NAME,
            "event": window.location.pathname.split('/')[3],
            "eventOutcome": "",
            "page": window.location.pathname.split('/')[3]
        });
    }, []);

    return (
        <OmdsGrid>
            <OmdsCell className="succes-card omds-mt-m-0 omds-mt-12 omds-c-flex omds-gap-7 omds-text-center omf-loan-success-container" span="row" span-md="3-10">
                <OmdsIcon className="succes-icon" size={100} name="check_circle" />
                <span className="h4-light">{`Thank you ${storeValues[FIRST_NAME].value} !`}</span>
                <span className="body-regular">
                    Your loan application has been successfully submitted!
                    One of our consultants will be in contact with you.
                </span>
                <span className="body-regular">
                    For enquiries or to track the progress of your application, please call 0860 000 886 and use your ID number as reference.
                </span>
                <span className='omds-hidden-m'>
                    <hr></hr>
                </span>
                <OmdsButton
                    iconName="arrow_forward"
                    data-qa="RightButton"
                    primary
                    regular
                    onClick={backToLoans}
                >
                    Finish
                </OmdsButton>
            </OmdsCell>
        </OmdsGrid>
    );
}
export default SuccessPage;
